import BusinessDatePicker from './BusinessDatePicker.vue';
//import DocumentCustomerSearch from './businessSearch/DocumentCustomerSearch.vue'

import { mapState } from 'vuex';
export default {
  components: {
    BusinessDatePicker
    //DocumentCustomerSearch
  },
  data: () => ({}),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document,
      documentStatus: state => state.businessDocumentGeneral.status,
      salespersons: state => state.businessDocumentGeneral.salespersons,
      branchOffices: state => state.businessDocumentGeneral.branchOffices,
      warehouses: state => state.businessDocumentGeneral.warehouses
    })
  },
  methods: {
    changeDate(newDate) {
      this.document.date = newDate;
    },
    loadData() {
      this.$store.dispatch('getAllDocumentStatus');
      this.$store.dispatch('getAllSalespersons');
      this.$store.dispatch('getAllBranchOffices');
      this.$store.dispatch('getAllWarehouses');
    }
  },
  mounted() {
    this.loadData();
  }
};
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VCard, {
    attrs: {
      "flat": ""
    }
  }, [_c(VCardText, [_c(VForm, {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c(VExpansionPanels, {
    attrs: {
      "multiple": "",
      "accordion": "",
      "value": _vm.expanded,
      "flat": ""
    }
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" Allgemein ")]), _c(VExpansionPanelContent, [_c('DocumentGeneral')], 1)], 1), _c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" Liefer- und Zahlungsdetails ")]), _c(VExpansionPanelContent, [_c('DocumentDeliveryBillingDetails')], 1)], 1), _c(VExpansionPanel, {
    attrs: {
      "disabled": ""
    }
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" Verordnungen "), _c('span', {
    staticClass: "ml-3"
  }, [_c(VChip, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-bullhorn-outline ")]), _vm._v(" Bald Verfügbar ")], 1)], 1)]), _c(VExpansionPanelContent, [_c('DocumentPrescription')], 1)], 1), _c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" Positionen ")]), _c(VExpansionPanelContent, [_c('DocumentPositions')], 1)], 1)], 1), _c(VContainer, [_c(VRow, {
    staticClass: "d-flex"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c(VBtn, {
    attrs: {
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": function ($event) {
        return _vm.createDocument();
      }
    }
  }, [_vm._v(" Erstellen ")])], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
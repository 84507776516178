import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date = $event;
      },
      "update:return-value": function ($event) {
        _vm.date = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VTextField, _vm._g(_vm._b({
          attrs: {
            "label": _vm.name,
            "outlined": "",
            "prepend-inner-icon": "mdi-calendar",
            "readonly": "",
            "rules": [v => !!v || 'Datum muss ausgewählt werden'],
            "requried": ""
          },
          model: {
            value: _vm.componentDate,
            callback: function ($$v) {
              _vm.componentDate = $$v;
            },
            expression: "componentDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary",
      "no-title": "",
      "scrollable": ""
    },
    model: {
      value: _vm.componentDate,
      callback: function ($$v) {
        _vm.componentDate = $$v;
      },
      expression: "componentDate"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.menu = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu.save(_vm.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
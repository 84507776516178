import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.documentStatus,
      "item-text": "name",
      "return-object": "",
      "label": "Status",
      "prepend-inner-icon": "mdi-progress-check",
      "outlined": "",
      "rules": [v => !!v || 'Verkäufer muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.status,
      callback: function ($$v) {
        _vm.$set(_vm.document, "status", $$v);
      },
      expression: "document.status"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.salespersons,
      "item-value": "lfdnr",
      "item-text": "name",
      "label": "Verkäufer",
      "prepend-inner-icon": "mdi-account-tie",
      "outlined": "",
      "rules": [v => !!v || 'Verkäufer muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.salespersonId,
      callback: function ($$v) {
        _vm.$set(_vm.document, "salespersonId", $$v);
      },
      expression: "document.salespersonId"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.branchOffices,
      "item-text": "name",
      "return-object": "",
      "label": "Filiale",
      "prepend-inner-icon": "mdi-office-building-outline",
      "outlined": "",
      "rules": [v => !!v || 'Filiale muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.branchOffice,
      callback: function ($$v) {
        _vm.$set(_vm.document, "branchOffice", $$v);
      },
      expression: "document.branchOffice"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.warehouses,
      "return-object": "",
      "item-text": "name",
      "label": "Lager",
      "prepend-inner-icon": "mdi-warehouse",
      "outlined": "",
      "rules": [v => !!v || 'Lager muss ausgewählt werden'],
      "required": ""
    },
    model: {
      value: _vm.document.warehouse,
      callback: function ($$v) {
        _vm.$set(_vm.document, "warehouse", $$v);
      },
      expression: "document.warehouse"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "md": "6",
      "lg": "4",
      "xl": "4"
    }
  }, [_c('BusinessDatePicker', {
    attrs: {
      "name": _vm.document.documentTypeId == 35 ? 'Wunschtermin' : 'Bindungsfrist',
      "date": _vm.document.date
    },
    on: {
      "changed-date": _vm.changeDate
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import axios from 'axios';
import { mapState } from 'vuex';
import DocumentPositionSearch from './businessSearch/DocumentPositionSearch.vue';
export default {
  components: {
    DocumentPositionSearch
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    positionHeaders: [{
      text: 'Artikelnummer',
      align: 'start',
      value: 'articleInformation.artNr'
    }, {
      text: 'Artikelbezeichnung',
      align: 'start',
      value: 'articleInformation.articleDescription'
    }, {
      text: 'Sachmerkmal',
      align: 'start',
      value: 'characteristic.id'
    }, {
      text: 'Aktionen',
      align: 'center',
      value: 'actions'
    }],
    quantityRules: [v => !!v || 'Menge muss eingegeben werden', v => /^[0-9]+$/.test(v) || 'Menge muss eine Zahl sein'],
    netPriceRules: [v => !!v || 'Nettopreis muss eingegeben werden', v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Nettopreis muss eine Zahl sein'],
    grossPriceRules: [v => !!v || 'Bruttopreis muss eingegeben werden', v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Bruttopreis muss eine Zahl sein'],
    discountRules: [v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Rabatt muss eine Zahl sein']
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    }),
    calculateSumPrices(position) {
      var sumPrices = {
        sumNetPrice: position.pricing.netPrice * position.quantity,
        sumGrossPrice: position.pricing.grossPrice * position.quantity
      };
      return sumPrices;
    }
  },
  methods: {
    calculateNetPrice(position) {
      position.pricing.netPrice = position.pricing.grossPrice / (position.pricing.valueAddedTax.tax + 1);
    },
    calculateGrossPrice(position) {
      position.pricing.grossPrice = position.pricing.netPrice * (position.pricing.valueAddedTax.tax + 1);
    },
    async changedCharacteristic(position) {
      var articleId = position.articleInformation.artNr;
      var characteristicId = position.characteristic.id;
      var possibleValueAddedTaxes = position.pricing.possibleValueAddedTaxes;
      await axios.get(`v1/business/document/positions/article/${articleId}/characteristic/${characteristicId}/pricing`).then(response => {
        position.pricing = response.data;
      });
      position.pricing.possibleValueAddedTaxes = possibleValueAddedTaxes;
    },
    removePosition(item) {
      this.document.positions = this.document.positions.filter(position => {
        return item.id !== position.id;
      });
    }
  }
};
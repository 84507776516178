export default {
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    date: {
      type: Date,
      required: true
    }
  },
  data: () => ({
    componentDate: null,
    data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
    menu: false
  }),
  watch: {
    componentDate(newVal) {
      this.$emit('changed-date', newVal);
    }
  },
  mounted() {
    this.componentDate = this.date;
  }
};
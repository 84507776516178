import "core-js/modules/es.array.push.js";
import DocumentGeneral from '../../components/business/DocumentGeneral.vue';
import DocumentDeliveryBillingDetails from '../../components/business/BusinessDeliveryBilling.vue';
import DocumentPrescription from '../../components/business/DocumentPrescription.vue';
import DocumentPositions from '../../components/business/DocumentPositions.vue';
import { DocumentClass } from '../../models/business/BusinessClasses';
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  components: {
    DocumentGeneral,
    DocumentDeliveryBillingDetails,
    DocumentPrescription,
    DocumentPositions
  },
  data: () => ({
    expanded: [0, 1, 3],
    valid: false
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document
    })
  },
  methods: {
    async createDocument() {
      await this.$refs.form.validate();
      if (this.valid) {
        this.requestDocumentCreation();
      }
    },
    requestDocumentCreation() {
      axios.post(`v1/business/document/create`, this.document).then(response => {
        this.$router.push({
          path: '/crm/addresses/' + response.data.customerId + '/documents/' + response.data.wfCid
        });
      });
    }
  },
  mounted() {
    this.document.customerId = this.$route.query.address;
    this.document.typeId = this.$route.query.documentTypeId;
  },
  destroyed() {
    this.document = DocumentClass.getEmptyDocument();
  }
};
import { mapState } from 'vuex';
import BusinessDatePicker from './BusinessDatePicker.vue';
export default {
  components: {
    BusinessDatePicker
  },
  data: () => ({
    discountRules: [v => /^-?\d+(?:[.,]\d+)?$/.test(v) || 'Rabatt muss eine Zahl sein']
  }),
  computed: {
    ...mapState({
      document: state => state.businessDocument.document,
      deliveryTypes: state => state.businessDocumentDeliveryBilling.deliveryTypes,
      deliveryConditions: state => state.businessDocumentDeliveryBilling.deliveryConditions,
      billingTypes: state => state.businessDocumentDeliveryBilling.billingTypes,
      billingConditions: state => state.businessDocumentDeliveryBilling.billingConditions,
      billingCurrencies: state => state.businessDocumentDeliveryBilling.billingCurrencies
    })
  },
  methods: {
    changedDeliveryDate(newDate) {
      this.document.delivery.date = newDate;
    },
    changedBillingDate(newDate) {
      console.log(newDate);
      this.document.billing.date = newDate;
    },
    loadData() {
      this.$store.dispatch('getAllDeliveryTypes');
      this.$store.dispatch('getAllDeliveryConditions');
      this.$store.dispatch('getAllBillingTypes');
      this.$store.dispatch('getAllBillingConditions');
      this.$store.dispatch('getAllBillingCurrencies');
    }
  },
  mounted() {
    this.loadData();
  }
};